import { AirCubeModel, type HeatCubeModel } from "@prisma/client";

import {
	DEMO_CUSTOMER_EMAIL,
	DEMO_HEATING_INSTALLER_EMAIL,
	TEST_ADMIN_EMAIL,
	TEST_CUSTOMER_EMAIL,
} from "~/constants/global.ts";

export function isDemoUser(email: string) {
	return (
		email === DEMO_CUSTOMER_EMAIL || email === DEMO_HEATING_INSTALLER_EMAIL
	);
}

export function isE2ETestsUser(email: string) {
	return email === TEST_ADMIN_EMAIL || email === TEST_CUSTOMER_EMAIL;
}

export const log = {
	/* eslint-disable no-console */
	info(id: string, message: string | object) {
		console.info(`APP INFO - ${id.toUpperCase()}:`, message);
	},
	warn(id: string, message: string | object) {
		console.warn(`APP WARNING - ${id.toUpperCase()}:`, message);
	},
	error(id: string, message: string | object) {
		console.error(`APP ERROR - ${id.toUpperCase()}:`, message);
	},
	/* eslint-enable no-console */
};

export type DeviceType = "airCube" | "heatCube";

export function getDeviceTypeUrlPath(model: AirCubeModel | HeatCubeModel) {
	const deviceMainType = getDeviceType(model);
	return deviceMainType === "airCube" ? "air-cubes" : "heat-cubes";
}

export function getDeviceType(model: AirCubeModel | HeatCubeModel): DeviceType {
	const isAirCube = Object.keys(AirCubeModel).includes(model);
	return isAirCube ? "airCube" : "heatCube";
}

export function addCookieToHeaders(
	headers: ResponseInit["headers"] = new Headers(),
	value: string | null,
) {
	if (!value) return headers;
	if (headers instanceof Headers) {
		headers.append("Set-Cookie", value);
	} else if (Array.isArray(headers)) {
		headers.push(["Set-Cookie", value]);
	} else {
		headers["Set-Cookie"] = value;
	}
	return headers;
}
